<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text"><b>Layanan</b> website Klinik IONA</div>
      <b-button squared variant="success" @click="btnAddOnClick()"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table purpose="master" />
          </template>
        </Card>
      </div>
    </div>

    <b-card no-body class="overflow-hidden">
      <b-row no-gutters>
        <b-col md="6">
          <b-card-img
            src="/images/website-klinik-hayandra.png"
            class="rounded-0"
          ></b-card-img>
        </b-col>
        <b-col md="6">
          <Form :form="form" :route="'website-services'" v-if="FormEditActv == false && FormDetailActv == false" />
          <FormEdit :form="form" :route="'website-services/' + form.id" v-if="FormEditActv"/>
          <FormDetail :form="form" :route="'website-services/' + form.id" v-if="FormDetailActv"/>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Table from "@/component/websites/ServiceTable.vue";
import Form from "@/component/websites/ServiceForm.vue";
import FormEdit from "@/component/websites/ServiceFormEdit.vue";
import FormDetail from "@/component/websites/ServiceFormDetail.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "website-service",
  data() {
    return {
      form: {
        title: "",
        description: "",
        status: "",
        order: "",
        image: [],
      },
      FormEditActv: false,
      FormDetailActv: false,
    };
  },
  components: {
    Card,
    Table,
    Form,
    FormEdit,
    FormDetail,
  },
  methods: {
    resetFormOnNew() {
      // reset form on new (tambah data)
      var self = this;
      Object.keys(this.form).forEach(function (key, index) {
        if (key === "image") {
          self.form[key] = [];
        } else if(key === "_method"){
          self.form[key] = "post"
        } else {
          self.form[key] = "";
        }
      });
    },

    scrollToElement(options) {
      let el = this.$el.getElementsByClassName("pagination")[0];
      el = el ?? this.$el.getElementsByClassName("table-setting")[0];
      console.log("el", el);
      if (el) {
        el.scrollIntoView(options);
        // reset form on new (tambah data)
        this.resetFormOnNew();
      }
    },

    scrollToTop(options) {
      // window.scrollTo(0, 0);
      let el = this.$el.getElementsByClassName("alert")[0];
      let er = this.$el.getElementsByClassName("alert");
      // el = el ?? this.$el.getElementsByClassName("table-setting")[0];
      console.log("el", el);
      console.log("er", er);
      if (el) {
        el.scrollIntoView(options);
        // reset form on new (tambah data)
        this.resetFormOnNew();
      }
    },

    btnAddOnClick() {
      this.scrollToElement({ behavior: "smooth" });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Website", route: "" },
      { title: "CMS", route: "" },
      { title: "Layanan", route: "" },
    ]);

    // Add Website Service
    this.$root.$on("updateWebsiteService", () => {
      // reset form on new (tambah data)
      this.scrollToTop({ behavior: "smooth" });
      this.resetFormOnNew();
    });

    // Cancel Add Website Service
    this.$root.$on("cancelAddWebsiteService", () => {
      // reset form on new (tambah data)
      this.scrollToTop({ behavior: "smooth" });
      this.resetFormOnNew();
    });

    //  Edit Website Service
    this.$root.$on("getWebsiteServiceById", (data) => {
      this.FormDetailActv = false
      this.FormEditActv = true
     this.scrollToElement({ behavior: 'smooth' })
      console.log("data", data)

      if(data.status == 0){
        data.status = false
      }else if(data.status == 1){
        data.status = true
      }

     this.form = data
     console.log("form", this.form)
     this.form['_method'] = 'put'
    });

    // Cancel Edit
    this.$root.$on("cancelWebsiteServiceUpdate", () => {
      this.FormEditActv = false
      this.scrollToTop({ behavior: 'smooth' })
      this.resetFormOnNew()
    });

    // Detail Web Service
    this.$root.$on("getWebsiteServiceByIdDetail", (data) => {
      this.FormDetailActv = true
      this.FormEditActv = false
     this.scrollToElement({ behavior: 'smooth' })
      console.log("data", data)

      if(data.status == 0){
        data.status = false
      }else if(data.status == 1){
        data.status = true
      }
     this.form = data
    });

      // Close Detail
    this.$root.$on("closeWebsiteServiceDetail", () => {
      this.FormDetailActv = false
      this.scrollToTop({ behavior: 'smooth' })
      this.resetFormOnNew()
    });
  },
};
</script>
